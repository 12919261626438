import React, {useState} from "react";
import Logo from '../../../images/logo.svg'
import {GrFacebookOption, GrInstagram, GrLinkedinOption} from "react-icons/gr"
import {FaPhoneAlt} from "react-icons/fa"   
import './footer.less'

export default ()=>{

    const[showText, setShowText] = useState(false);

    return(
        <section className="footer">
            <div className="footer-grid">
                <article className="brand">
                    <img src={Logo} alt="Alex Yadgarov & Associates" />
                    <h3><a href="tel:212-365-4255"><span><FaPhoneAlt/></span> 212-365-4255</a></h3>
                </article>
                <article className="location">
                    <p>Local Office</p>
                    <br/>
                    <p>1 Cross Island Plaza,
                        #203
                        Rosedale, NY 11422
                        <span><a href="https://www.google.com/maps/place/Alex+Yadgarov+%26+Associates/@40.6743506,-73.7340732,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x2ace7bd0cfc4e36e!8m2!3d40.6742618!4d-73.7316245" target="_blank">Map & Directions [+]</a></span>
                    </p>
                </article>
                <article className="quick-links">
                    <p>Quick Links</p>
                    <br/>
                    <ul>
                        <li>
                            <a href="#cases">Casos</a>
                        </li>
                        <li>
                            <a href="#reviews">Reseñas</a>
                        </li>
                        <li>
                            <a href="#team">Conozca a nuestro equipo</a>
                        </li>
                        <li>
                            <a href="#car">Accidentes Auto</a>
                        </li>
                    </ul>
                </article>
                <article className="social">
                    <p>Follow Us</p>
                    <br />
                    <div className="social-container">
                        <p> <a href="https://www.facebook.com/AlexYadgarovLaw/" target="_blank"><GrFacebookOption/></a> </p>
                        <p> <a href="https://www.linkedin.com/in/alex-m-yadgarov-esq-32101b14/" target="_blank"> <GrLinkedinOption/></a> </p>
                    </div>
                </article>
            </div>
            <div className="mobile-cta">
                <h3><a href="tel:212-365-4255"><span><FaPhoneAlt/></span> 212-365-4255</a></h3>
            </div>
        </section>
    )
}