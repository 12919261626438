import React from 'react';
import Navbar from '../Navbar/Navbar'
import {UnderlineSm} from '../Underlines/Underlines'
import Alex from '../../../images/image-2.png'
import {GiCheckMark} from 'react-icons/gi'
import {AiFillStar} from 'react-icons/ai'
import './hero.less'

export default ()=>{

    return(
        <main>  
            <Navbar/>
            <section className="hero-car-accident" id="hero">
                <section className="hero-grid">
                    {/* ================ COL 1 ================ */}
                    <article className="alex">
                        <img src={Alex} alt="Alex Yadgarov" />
                    </article>

                    {/* ================ COL 3 ================ */}
                    <article className="case-eval">
                        <div className="case-eval-wrapper">
                            <h2> <span>¿Sufrió una lesión por accidente automovilístico?</span> Obtenga la compensación máxima.</h2>
                            <UnderlineSm/>
                            <p>Su Abogado Preparado. Obtenga lo que se merece: solicite su consulta <span>100%</span> gratuita ahora </p>
                            <div className="point-container">
                                <ul>
                                    <li><span><GiCheckMark/></span> Millones ganados para clientes con lesiones</li>
                                    <li><span><GiCheckMark/></span> Miles de casos de lesiones ganados</li>
                                    <li><span><GiCheckMark/></span> Equipo legal galardonado</li>
                                    <li><span><GiCheckMark/></span> 100% sin cargo a menos que gane</li>
                                    <li><span><GiCheckMark/></span> Bufete de abogados de 5 estrellas</li>
                                </ul>
                                <a href="tel:212-365-4255" className='btn'>
                                    Obtenga su consulta GRATIS ahora 212-365-4255
                                </a>
                            </div>
                            <div className="review-container">
                                <div className="single-review">
                                    <p>
                                        “Great set of people to work with.”
                                    </p>
                                    <div>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                    </div>
                                </div>
                                <div className="single-review">
                                    <p>
                                        “The staff really work hard to win your case and give you the best ourcome!!”
                                    </p>
                                    <div>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                    </div>
                                </div>
                                <div className="single-review">
                                    <p>
                                        “Good quality and very professional service.”
                                    </p>
                                    <div>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                        <AiFillStar/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>
            </section>
        </main>
    )
}