import React from 'react'
import CarAccident from '../../../images/car-accidents.jpg'
import './car-accident.less'
export default () => {
  return (
    <section className="car-accident" id='car'>
        <article className="container">
            {/* ATTORNER */}
            <div className="attorney-container">
                <div className="attorney-description">
                    <h2>Abogados de Accidentes automobilísticos en Rosedale.</h2>
                    <h3>
                        Alex Yadgarov & Asociados le ofrece una Primera Consulta Gratuita
                    </h3>
                    <p>
                        Nuestros abogados saben lo doloroso que pueden ser los accidentes automovilísticos. Luego de haber recibido tratamiento de acuerdo a los síntomas presentados, es lógico que presente dudas con respecto al proceso a seguir para recuperar cualquier daño sufrido a raíz del accidente, o si tiene derecho a compensación alguna. Nuestros abogados especializados en accidentes automovilísticos se complacen en informarle que estamos a su disposición en Queens o cualquier localidad aledaña. Estamos comprometidos a hacerle el proceso mucho mas amigable, de la mano con nuestros abogados, y acoplándonos a sus necesidades personales.
                    </p>
                </div>
                <div className="img-container">
                    <img src={CarAccident} alt="" />
                </div>
            </div>
            {/* CALL ALEX */}
            <div className="call-alex">
                <p className='p'>
                    Llámenos para una consulta gratuita al numero <a href="tel:212-365-4255">212-365-4255</a>. Hablamos español.
                </p>
            </div>
            {/* REPRESENT */}
            <div className="represent">
                <h3>
                    Abogados confiables y efectivos.
                </h3>
                <p className="pad-2-text">
                    Desde el 2009, el grupo de Alex Yadgarov & Asociados ha priorizado el trato de nuestra clientela al lidiar con casos de accidentes automovilísticos en Rosedale. Ofrecemos asistencia en el idioma de su preferencia, ya sea Inglés, Español o Ruso. De igual modo, haremos todo lo que esté en nuestras manos a fin de que usted pueda obtener una compensación por sus lesiones.
                </p>    
                <div className="list-container">
                    <p className="p">
                        Nuestros abogados pueden proporcionarle ayuda para obtener compensación por las siguientes cuestiones:
                    </p>
                    <ol>
                        <li>Dolor a raíz de las lesiones sufridas</li>
                        <li>Gastos medicos</li>
                        <li>Daños a la propiedad</li>
                        <li>Salarios caídos</li>
                        <li>Gastos adicionales, tales como alquiler de vehículos y pago de multas de aparcamiento</li>
                    </ol>
                </div>
                <div className="list-container">
                    <p className="p">
                        Las lesiones sufridas a raíz de accidentes automovilísticos, pueden ser tanto físicas como mentales, y pueden incluir:
                    </p>
                    <div className="list-grid">
                        <ul>
                            <li>Latigazo</li>
                            <li>Lesiones en la cabeza</li>
                            <li>Hemorragias internas</li>
                        </ul>
                        <ul className='padding'>
                            <li>Fracturas</li>
                            <li>Trastornos causados por estrés postraumático</li>
                        </ul>
                    </div>
                </div>
                <p className="pad-2-text">
                    Si usted no esta seguro de que su caso califique para recibir compensación por las lesiones sufridas en el accidente, llámenos para una consulta gratis. Nosotros nos dirigiremos a donde usted se encuentre, así podrá despreocuparse por el traslado.
                </p>
            </div>
            {/* NO FAULT */}
            <div className="no-fault">
                <h3>
                    Usted es nuestra prioridad
                </h3>
                <div className="pad-2-text">
                    Producto del trabajo y dedicación de nuestros abogados en cada caso, usted sentirá que su caso fue realmente atendido- hemos asumido diferentes casos por parte de un mismo cliente, como también hemos recibido clientes que han sido referidos por otros clientes.
                </div>
            </div>
            {/* FREE CONSULTATION */}
            <div className="call-alex">
                <p className="p">
                    No tiene que padecer su sufrimiento solo. Llame a Alex Yadgarov & Asociados al número <a href="tel:212-365-4255">212-365-4255</a> y podrá recibir asistencia por parte de nuestros equipo de abogados de Rosedale especializado en accidentes automovilísticos.
                </p>
            </div>
        </article>
    </section>
  )
}
