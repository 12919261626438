import * as React from "react"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.less'


// HERO
import Hero from '../../components/NLP/Hero/Hero'
// Awards
import Awards from '../../components/NLP/Awards/Awards'
// Stats
import Stats from '../../components/NLP/Stats/Stats'
// Guidance
import Guidance from '../../components/NLP/Guidance/Guidance'
// Review
import Reviews from '../../components/NLP/Reviews/Reviews'
// Car Accident
import CarAccident from '../../components/NLP/CarAccident/CarAccident'
// Personal Injuries
import Injury from '../../components/NLP/Injury/Injury'
// Next
import Next from '../../components/NLP/Next/Next'
// Footer
import Footer from '../../components/NLP/Footer/Footer'

const IndexPage = () => {

  React.useEffect(()=>{
    const underlines = document.querySelectorAll('.underlines');
    const underlinesSm = document.querySelectorAll('.underline-container');
    const underlinesContact = document.querySelectorAll('.underline-contact');
    const underlinesOne = document.querySelectorAll('.underline-one');
    const underlinesTwo = document.querySelectorAll('.underline-two');
    const underlinesInj = document.querySelector('.underline-injury');

    let options = {
      root: document.querySelector('#scrollArea'),
      rootMargin: '0px',
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entries)=>{
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0){
          if(entry.target.classList.contains("underline-one")){
            entry.target.style.animation = "anim3 0.65s forwards linear"; 
          }
          else if(entry.target.classList.contains("underline-two")){
            entry.target.style.animation = "anim3 0.5s forwards linear"; 
          }else{
            entry.target.style.animation = "anim 0.5s forwards linear"; 
          }
        }else{
          entry.target.style.color = "none";
        }
      })
    }, options);

    underlines.forEach(underline => {
      observer.observe(underline);
    })
    underlinesSm.forEach(underline => {
      observer.observe(underline);
    })
    underlinesContact.forEach(underline => {
      observer.observe(underline);
    })
    observer.observe(underlinesInj);

    underlinesOne.forEach(underline => {
      observer.observe(underline)
    })
    
    underlinesTwo.forEach(underline => {
      observer.observe(underline)
    })
  }, [])

  return (
    <>
      <Hero/>
      <Awards/>
      <div className="bck">
        <Stats/>
        <Guidance/>
        <Reviews/>
      </div>
      <CarAccident/>
      <div className="bck">
        <Injury />
      </div>
      <Next/>
      <Footer/>
    </>
  )
}

export default IndexPage