import React, {Component} from 'react';
import {useEffect, useState} from 'react';
import { Underlines } from '../Underlines/Underlines';
import Slider from "react-slick";
import CertificateOne from "../../../images/certficates/ABA.png"
import CertificateTwo from "../../../images/certficates/Expertise.png"
import CertificateThree from "../../../images/certficates/Lawyer\ of\ distinction.png"
import CertificateFour from "../../../images/certficates/NYSBA.svg"

import "./awards.less" 

export default class MultipleItems extends Component {
  render() {
    return (
        <section className="awards" id="awards">
            <article className="awards-wrapper sm">
                <Slider 
                    swipeToSlide={true}
                    slidesToShow={2}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                >
                    <img src={CertificateTwo} alt="" className="single-award" />
                    <img src={CertificateThree} alt="" className="single-award" />
                    <img src={CertificateFour} alt="" className="single-award" />
                    <img src={CertificateOne} alt="" className="single-award" />
                </Slider>        
            </article>
            <article className="awards-wrapper md">
                <Slider 
                    swipeToSlide={true}
                    slidesToShow={3}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                >
                    <img src={CertificateTwo} alt="" className="single-award" />
                    <img src={CertificateThree} alt="" className="single-award" />
                    <img src={CertificateFour} alt="" className="single-award" />
                    <img src={CertificateOne} alt="" className="single-award" />
                </Slider>        
            </article>
            <article className="awards-wrapper xl">
                <Slider 
                    swipeToSlide={true}
                    slidesToShow={4}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                >
                    <img src={CertificateTwo} alt="" className="single-award" />
                    <img src={CertificateThree} alt="" className="single-award" />
                    <img src={CertificateFour} alt="" className="single-award" />
                    <img src={CertificateOne} alt="" className="single-award" />
                </Slider>        
            </article>
        </section>
    );
  }
}